import {
 json,
 redirect,
 type LoaderFunctionArgs,
 type MetaFunction,
} from '@remix-run/node'
import { useLoaderData, useNavigation } from '@remix-run/react'
import { User } from '@wundergraph/sdk/client'
import React, { useEffect, useState } from 'react'
import { createClientFromCookies, useUser } from '../../../lib/wundergraph'
import BackgroundColors from './BackgroundColors'
import Footer from './Footer'
import Hero from './Hero'
import Kroger_ZHZW from './KrogerZHZW'
import OSSLogos from './OSSLogos'
import Stats from './Stats'
import Steps from './Steps'
import ValueProp from './ValueProp'

export default function Index() {
 const signupUrl = useLoaderData<typeof loader>()
 const {
  data: user,
  isLoading: userLoading,
  error: error,
  isValidating: isValidating,
 } = useUser()

 const { state } = useNavigation()
 if (state === 'loading') {
  return (
   <>
    <div className="flex h-screen items-center justify-center align-middle">
     <div className="h-32 w-32 animate-spin rounded-full border-b-2 border-t-2 border-warmTerracota-700"></div>
    </div>
   </>
  )
 }

 return (
  <>
   {/* <BackgroundColors /> */}
   <Hero signupUrl={signupUrl} />

   <>
    <div className="mx-auto flex max-w-none justify-center pb-3">
     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
      <Steps />
      <Kroger_ZHZW />
     </div>
    </div>
    <Stats />
    <ValueProp />
    {/* <OSSLogos /> */}
    <Footer />
   </>
  </>
 )
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
 // Check if the user has an existing profile if not
 // redirect to profile creation page

 const client = await createClientFromCookies(request)
 const signupUrl = process.env.KEYCLOAK_SIGNUP_URL
  ? process.env.KEYCLOAK_SIGNUP_URL
  : ''

 try {
  const user = await client.fetchUser({ revalidate: true })
  const result = await client.query({
   operationName: 'StrapiCustom/GetUserProfiles',
   input: {
    externalIdentifier: user.userId ? user.userId : '',
   },
  })

  if (user && result.data?.strapi_userProfiles?.data.length == 0) {
   return redirect('/profile')
  } else {
   return redirect('/donors')
  }
 } catch (error) {
  return signupUrl
 }
}
